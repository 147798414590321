<template>
    <div>
        <div class="breadcrumb">
            <h1>Registro de Usuarios</h1>
        </div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-3">
                                <router-link :to="{ name: 'usuario-configuracion-create' }">
                                    <button class="btn th-custom-color">Nuevo</button>
                                </router-link>
                            </div>
                            <div class="col-sm-3 offset-sm-6">
                            <div class="text-right">
                            <label class="switch switch-success mr-3">
                                <input id="filterbyState"
                                type="checkbox"
                                :checked="estado"
                                v-model="estado"
                                @change="getIndex"
                                >
                                <span class="slider"></span>
                                <span>{{ labelActivos }}</span>
                            </label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mb-1">
                        <div class="col-sm-3">
                            <div class="btn-group">
                            <span class="pt-2 mr-1">Mostrar </span>
                            <b-form-select
                                v-model="porPagina"
                                :options="paginaOpciones">
                            </b-form-select>
                            <span class="pt-2 ml-1"> Registros</span>
                            </div>
                        </div>
                        <div class="col-sm-3 offset-sm-6 text-right">
                            <div class="form-group row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <input type="text"
                                    class="form-control"
                                    v-model="filter"
                                    autocomplete="off"
                                    @keyup="filtrarBusqueda"
                                    placeholder="Buscar"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <b-col md="3" v-for="(user, index) in listaUsuarios"
                        v-bind:item="user"
                        v-bind:index="index"
                        v-bind:key="index">
                            <b-card class="card-profile-1 mb-30 text-center">
                                <div class="row">
                                    <div v-if="estado" class="col-sm-6 text-left" style="display:-webkit-box;">
                                        <i class="nav-icon i-Close-Window font-weight-bold text-danger" title="eliminar"
                                            style="cursor: pointer; font-size:20px" @click="showDeleteConfirmation(user)">
                                        </i>
                                        <router-link
                                        style="margin:12px;"
                                        :to="{ name: 'usuario-configuracion-edit', params: {id: encrypt(user.id)}}"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Editar">
                                            <i class="nav-icon i-Pen-5 text-success font-weight-bold" 
                                                style="cursor: pointer; font-size:20px">
                                            </i>
                                        </router-link>
                                    </div>
                                    <div class="col-sm-6 text-right" v-if="estado">
                                        <label for="numero">N° {{ user.numero }}</label>
                                    </div>
                                </div>
                                <div class="avatar box-shadow-2 mb-3">
                                    <img :src="user.imagen" alt="">
                                </div>
                                <h5 class="m-0">{{ user.nombre_completo }}</h5>
                                <button class="btn th-custom-color btn-rounded" 
                                    style="cursor: auto;"
                                    v-if="user.estado_usuario == 1">Activo</button>
                                <button class="btn btn-warning btn-rounded" style="cursor: auto;" v-else>Sin acceso</button>
                                <label style="float:right;"> ID {{ user.numero_empresa }} </label>
                            </b-card>
                        </b-col>
                    </b-row>
                    <div class="row">
                        <div class="col-sm-8"></div>
                        <div class="col-sm-4">
                            <b-pagination 
                                v-model="paginaActual"
                                :total-rows="totalFila"
                                :per-page="porPagina"
                                class="my-0 float-right"
                                @input="getIndex">
                            </b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <theModalDestroy/>
    </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import theModalDestroy from '../components/theModalDestroyUsuario.vue';

export default {
    name: 'index',
    components: {
        theModalDestroy,
    },
    created() {
        this.getIndex();
    },
    data(){
        return {
            delayTimeout: null,
        };
    },
    methods: {
        ...call('datoUsuaurio/usuarioIndex', ['listarUsuarios']),
        getIndex() {
            this.listarUsuarios();
            this.load = true;
        },
        filtrarBusqueda() {
            const DELAY = 400;
            clearTimeout(this.delayTimeout);
            this.delayTimeout = setTimeout(this.getIndex, DELAY);
        },
        showDeleteConfirmation(item) {
            this.modalDestroyConfirmation = true;
            this.registro = item;
        },
        encrypt(id) {
            return btoa(id);
        },
    },
    computed: {
        ...get('datoUsuaurio/usuarioIndex', [
            'listaUsuarios',
        ]),
        ...sync('datoUsuaurio/usuarioIndex', [
            'filter',
            'totalFila',
            'paginaActual',
            'porPagina',
            'estado',
            'paginaOpciones',
        ]),
        ...sync('datoUsuaurio/usuarioDestroy', [
            'modalDestroyConfirmation',
            'registro',
        ]),
        labelActivos() {
            if (this.estado) {
                return 'Activos';
            }
            return 'Inactivos';
        },
    },
    watch: {
        // porPagina() {
        //     this.getIndex();
        // },
    },
}
</script>